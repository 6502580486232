#article-content {
  color: rgb(255, 255, 255);
  font-size: 0.9em;
  margin: 0 auto;
  padding: 2em 0;
  background-color: rgb(25, 25, 25);
}

p.article-paragraph {
  font-family: 'Georgia', serif;
  margin: 1.5em 0;
  line-height: 1.3;
}

#article-content > h3 {
  margin: 0;
  font-family: 'Solano-Gothic-Bold-Cap', sans-serif;
  font-size: 2em;
}

.awssld {
  --organic-arrow-thickness: 4px;
  --organic-arrow-height: 40px;
  --slider-height-percentage: 60%;
  --loader-bar-color: rgba(255, 255, 255, 0.5);
  --loader-bar-height: 6px;
  --control-button-width: 10%;
  --control-button-height: 25%;
  --control-button-opacity: 0.75;
  --control-button-hover-opacity: 0.95;
  --control-button-background: #fff0;
  --transition-bezier: cubic-bezier(0.5, 0.075, 0.25, 0.95);
  --slider-transition-duration: 500ms;
  --organic-arrow-color: #fff;
  --organic-arrow-border-radius: 0;
  --control-bullet-color: #6a6a6a;
  --control-bullet-active-color: #6a6a6a;
  --content-background-color: #000;
}

.awssld__content > img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  margin: auto;
}

.awssld__controls__arrow-left,
.awssld__controls__arrow-right {
  transition-delay: 0s;
  transition-duration: 0.175s;
  transition-timing-function: ease-in-out;
}

#article-content > div > video {
  width: 100%;
  height: auto;
}

.standalone-img {
  width: 100%;
}

.caption {
  display: block;
  margin-left: auto;
  margin-top: 1em;
  margin-right: auto;
  width: 100%;
  font-style: italic;
  font-size: 0.9em;
}

.byline {
  margin: 0;
  font-family: 'Solano-Gothic-Bold-Cap', sans-serif;
  font-size: 1.5em;
}
