.header-bg {
  width: 100%;
  height: 100vh;
  background-size: cover;
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
  background-attachment: fixed;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

#header-video {
  filter: opacity(50%);
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 60%;
  height: 50%;
  color: white;
}

.header-content div {
  margin: 0.1em 0;
}

#title-text {
  font-size: 4em;
  font-family: 'Solano-Gothic-Bold-Cap', sans-serif;
  z-index: 0;
}

#subtitle-text {
  font-size: 3.5em;
  font-family: 'Solano-Gothic-Bold', sans-serif;
  z-index: 0;
}
